import React from 'react'

const FormContainer = ({ children }) => {
    return (
        <div className='conatiner flex justify-center items-center  '>
    
                {children}
    
        </div>
    )
}

export default FormContainer