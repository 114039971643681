export const CROP_LIST_REQUEST = 'CROP_LIST_REQUEST';
export const CROP_LIST_SUCCESS = 'CROP_LIST_SUCCESS';
export const CROP_LIST_FAILED = 'CROP_LIST_FAILED';

export const CROP_DELETE_REQUEST = 'CROP_DELETE_REQUEST';
export const CROP_DELETE_SUCCESS = 'CROP_DELETE_SUCCESS';
export const CROP_DELETE_FAILED = 'CROP_DELETE_FAILED';

export const CROP_CREATE_REQUEST = 'CROP_CREATE_REQUEST';
export const CROP_CREATE_SUCCESS = 'CROP_CREATE_SUCCESS';
export const CROP_CREATE_FAILED = 'CROP_CREATE_FAILED';
export const CROP_CREATE_RESET = 'CROP_CREATE_RESET';

export const CROP_UPDATE_REQUEST = 'CROP_UPDATE_REQUEST';
export const CROP_UPDATE_SUCCESS = 'CROP_UPDATE_SUCCESS';
export const CROP_UPDATE_FAILED = 'CROP_UPDATE_FAILED';
export const CROP_UPDATE_RESET = 'CROP_UPDATE_RESET';