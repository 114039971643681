export const NOTIFICATION_LIST_REQUEST = 'NOTIFICATION_LIST_REQUEST';
export const NOTIFICATION_LIST_SUCCESS = 'NOTIFICATION_LIST_SUCCESS';
export const NOTIFICATION_LIST_FAILED = 'NOTIFICATION_LIST_FAILED';
export const NOTIFICATION_LIST_RESET = 'NOTIFICATION_LIST_RESET';

export const NOTIFICATION_DELETE_REQUEST = 'NOTIFICATION_DELETE_REQUEST';
export const NOTIFICATION_DELETE_SUCCESS = 'NOTIFICATION_DELETE_SUCCESS';
export const NOTIFICATION_DELETE_FAILED = 'NOTIFICATION_DELETE_FAILED';

export const NOTIFICATION_STATUS_REQUEST = 'NOTIFICATION_STATUS_REQUEST';
export const NOTIFICATION_STATUS_SUCCESS = 'NOTIFICATION_STATUS_SUCCESS';
export const NOTIFICATION_STATUS_FAILED = 'NOTIFICATION_STATUS_FAILED';