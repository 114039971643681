export const DISEASE_LIST_REQUEST = 'DISEASE_LIST_REQUEST';
export const DISEASE_LIST_SUCCESS = 'DISEASE_LIST_SUCCESS';
export const DISEASE_LIST_FAILED = 'DISEASE_LIST_FAILED';

export const AI_SEARCH_REQUEST = 'AI_SEARCH_REQUEST';
export const AI_SEARCH_SUCCESS = 'AI_SEARCH_SUCCESS';
export const AI_SEARCH_FAILED = 'AI_SEARCH_FAILED';
export const AI_SEARCH_RESET = 'AI_SEARCH_RESET';


export const DISEASE_DETAIL_REQUEST = 'DISEASE_DETAIL_REQUEST';
export const DISEASE_DETAIL_SUCCESS = 'DISEASE_DETAIL_SUCCESS';
export const DISEASE_DETAIL_FAILED = 'DISEASE_DETAIL_FAILED';
export const DISEASE_DETAIL_RESET = 'DISEASE_DETAIL_RESET';


export const DISEASE_DELETE_REQUEST = 'DISEASE_DELETE_REQUEST';
export const DISEASE_DELETE_SUCCESS = 'DISEASE_DELETE_SUCCESS';
export const DISEASE_DELETE_FAILED = 'DISEASE_DELETE_FAILED';

export const DISEASE_CREATE_REQUEST = 'DISEASE_CREATE_REQUEST';
export const DISEASE_CREATE_SUCCESS = 'DISEASE_CREATE_SUCCESS';
export const DISEASE_CREATE_FAILED = 'DISEASE_CREATE_FAILED';
export const DISEASE_CREATE_RESET = 'DISEASE_CREATE_RESET';

export const DISEASE_UPDATE_REQUEST = 'DISEASE_UPDATE_REQUEST';
export const DISEASE_UPDATE_SUCCESS = 'DISEASE_UPDATE_SUCCESS';
export const DISEASE_UPDATE_FAILED = 'DISEASE_UPDATE_FAILED';
export const DISEASE_UPDATE_RESET = 'DISEASE_UPDATE_RESET';
